import React from 'react'
import type { NextPage } from 'next'
import { useSearchParams, useRouter } from 'next/navigation'
import { useAuth, useUser } from '@clerk/nextjs'
import {
  Button,
  styled,
  Stack,
  ThemeProvider,
  type Theme,
  Box,
  Dialog,
  Modal,
  Snackbar,
  Alert
} from '@mui/material'
import {
  format,
  addMinutes,
  differenceInMinutes,
  startOfDay,
  isSameDay
} from 'date-fns'
import {
  TitleBar,
  NavColumn,
  VisitFilterPopup,
  DashboardTopPane,
  StickyNotesModal,
  TimeRangeFilterPopup,
  UnifiedTable,
  FilterByPopup,
  DeleteVisitPopUp
} from '@/components'
import { CreateTaskModal } from '@/components/Task/CreateTaskModal'
import {
  formatAge,
  formatGender,
  formatPhoneNumber,
  formatInUTC,
  isEmpty,
  clearIndexedDB,
  parseTableDataFromAppointment,
  IS_METRIC_STORAGE_KEY
} from '@/utility'
import {
  useQueryGetClinicDashboard,
  useQueryGetComplaints,
  useQueryGetVisitTypes,
  useQueryGetProcedureTypes,
  useQueryGetRooms,
  useClinicStore,
  useQueryGetAccountUsers,
  useMutateSendFax,
  useQueryGetVisitSummary,
  useMutateUpdateOnlineAppointment,
  useReleaseVersionStore,
  useQueryGetExternalProviders,
  useGlobalStore,
  useQueryGetClinics,
  useMutateUpdateVisit,
  useTableTheme,
  useMutateDeleteVisit,
  useQueryDeletePublicAppointments,
  useMutateUpdateAppointmentStatus,
  useMutateUpdateVisitStatus,
  useQueryGetDashboardAppointments,
  type TaggedAppointment
} from '@/hook'
import {
  type DashboardContentPaneProps,
  type Procedure,
  type Visit,
  type DocumoFax,
  type ProcedureCPTCode,
  type ClinicDashboard,
  type DashboardProcedure,
  type OnlineAppointment
} from '@/types'
import {
  useGetAppointmentColumns,
  useGetOriginalColumns
} from '@/components/Columns'
import AddIcon from '@mui/icons-material/Add'
import { getProviderFullName } from '@/components/Columns/ColumnUtils'
import { getDailyPatientSummary } from '@/services'
import { SendRecordModal as SendRecord } from '@/components/Charting/Editor/IQBar/modals/SendRecord'
import { type MRT_SortingState } from 'material-react-table'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const DashboardContent = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100vh',
  backgroundColor: theme.palette.background.paper,
  gap: 2,
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
}))
const DashboardContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  paddingBottom: '0px'
})
const DashboardMainPane = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
  padding: '30px 20px',
  flex: '1'
}))
const DashboardContentPane = styled('div')(
  ({ isNavOpen }: DashboardContentPaneProps): any => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '60px',
    marginLeft: `${isNavOpen ? '80px' : '0px'}`,
    width: '100%'
  })
)
const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '15px'
})
const FilterButton = styled(Button)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  fontSize: '15px',
  fontWeight: 600,
  lineHeight: '26px',
  border: 'none',
  transition: 'none',
  '&:hover': {
    backgroundColor: 'white',
    textDecoration: 'underline'
  }
}))

interface TabInfo {
  title: string
  count: number
}

const initialTabInfo: TabInfo[] = [
  { title: 'Appointments', count: 0 },
  { title: 'Waiting Room', count: 0 },
  { title: 'Exam Started', count: 0 },
  { title: 'Completed', count: 0 }
]

type TabMapType = Record<string, 1 | 2 | 3>;

const tabMap: TabMapType = {
  'Waiting Room': 1,
  'Exam Started': 2,
  Completed: 3
}

const Dashboard: NextPage = () => {
  const { user } = useUser()
  const router = useRouter()
  const searchParams = useSearchParams()
  const previousNavOpen = searchParams?.get('previousNavOpen')
  const paginationIndex = searchParams?.get('pageIndex') ?? '0'
  const { getToken } = useAuth()

  const {
    currentDashboardTab,
    dashboardFilterList: filterList,
    setCurrentDashboardTab,
    setDashboardFilterList: setFilterList,
    lastDateUpdate
  } = useGlobalStore()

  const { releaseVersion, setReleaseVersion } = useReleaseVersionStore()
  const { clinics, clinicId } = useClinicStore()

  const { data: visitTypes } = useQueryGetVisitTypes(clinicId, getToken)
  const { data: complaints } = useQueryGetComplaints(getToken)
  const { data: procedureTypes } = useQueryGetProcedureTypes(clinicId, getToken)
  const { data: rooms } = useQueryGetRooms(getToken)
  const { refetch: getClinics } = useQueryGetClinics(getToken)
  const { data: clinicians } = useQueryGetAccountUsers(getToken)

  const [currentVisit, setCurrentVisit] = React.useState<Visit>()
  const [appointmentId, setAppointmentId] = React.useState<number>(0)
  const getFromLocalStorage = (key: string): string | null => {
    const dataString = localStorage.getItem(key) // Change sessionStorage to localStorage
    if (!dataString) return null

    const data = JSON.parse(dataString)
    const now = new Date()

    if (!isSameDay(new Date(data.timestamp), now)) {
      // If the stored date is from a previous day, return null to trigger a reset
      return null
    }

    return data.value
  }

  const saveToLocalStorage = (key: any, value: any): void => {
    const data = {
      value,
      timestamp: new Date().getTime()
    }
    localStorage.setItem(key, JSON.stringify(data))
  }

  const [dashboardFromDate, setDashboardFromDate] = React.useState<Date>(() => {
    const savedFromDate = getFromLocalStorage('dashboardFromDate')
    return savedFromDate ? new Date(savedFromDate) : startOfDay(new Date())
  })

  const [dashboardToDate, setDashboardToDate] = React.useState<Date>(() => {
    const savedToDate = getFromLocalStorage('dashboardToDate')
    return savedToDate ? new Date(savedToDate) : new Date()
  })

  React.useEffect(() => {
    saveToLocalStorage('dashboardFromDate', dashboardFromDate.toISOString())
  }, [dashboardFromDate])

  React.useEffect(() => {
    saveToLocalStorage('dashboardToDate', dashboardToDate.toISOString())
  }, [dashboardToDate])

  const [pagination, setPagination] = React.useState<{
    pageIndex: number
    pageSize: number
  }>({ pageIndex: 0, pageSize: 10 })

  const [sorting, setSorting] = React.useState<MRT_SortingState>([
    { id: 'default', desc: true }
  ])

  const [tabsInfo, setTabsInfo] = React.useState<TabInfo[]>(initialTabInfo)

  const [selectedTab, setSelectedTab] =
    React.useState<string>(currentDashboardTab)

  const [isNavOpen, setIsNavOpen] = React.useState<boolean>(
    previousNavOpen! === 'true'
  )

  const [fromDate, setFromDate] = React.useState<Date>(
    startOfDay(new Date(dashboardFromDate))
  )
  const [toDate, setToDate] = React.useState<Date>(new Date(dashboardToDate))

  const [dailyPatientSummary, setDailyPatientSummary] =
    React.useState<any>(null)

  const [createTaskOpen, setCreateTaskOpen] = React.useState<boolean>(false)
  const [stickyNotesOpen, setStickyNotesOpen] = React.useState<boolean>(false)
  const [currentPatientId, setCurrentPatientId] = React.useState<number>(0)
  const [tableData, setTableData] = React.useState<any[]>([])
  const [filterCategory, setFilterCategory] = React.useState<string>('')

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [filterByAnchorEl, setFilterByAnchorEl] =
    React.useState<HTMLButtonElement | null>(null)
  const [isSendRecordsOpen, setIsSendRecordsOpen] =
    React.useState<boolean>(false)
  const [hoverAssignRoomId, setHoverAssignRoomId] = React.useState<number>(0)
  const [hoverAssignProviderId, setHoverAssignProviderId] =
    React.useState<number>(0)
  const [isColumnFixed, setIsColumnFixed] = React.useState<boolean>(false)
  useQueryGetExternalProviders(getToken)
  const [toastMessage, setToastMessage] = React.useState<string>('')
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false)

  const updateOnlineAppointment = useMutateUpdateOnlineAppointment(getToken)
  const deletePublicAppointment = useQueryDeletePublicAppointments(getToken)
  const documoFaxMutation = useMutateSendFax(getToken)
  const updateVisitMutation = useMutateUpdateVisit(getToken)
  const deleteVisitMutation = useMutateDeleteVisit(getToken)
  const updateAppointmentStatus = useMutateUpdateAppointmentStatus(getToken)
  const updateVisitStatus = useMutateUpdateVisitStatus(getToken)

  const sortingMap: Record<string, 0 | 1 | 2 | 3 | 4 | 5 | 6> = {
    default: 0,
    patient_details: 1,
    status: 2,
    exam_started: 3,
    provider: 4,
    reason_type_of_visit: 5,
    time_since_arrival: 6
  }

  const {
    data: clinicDashboardData,
    refetch: loadClinicDashboardData,
    isLoading: isTableLoading
  } = useQueryGetClinicDashboard(
    clinicId,
    format(fromDate, 'yyyy-MM-dd'),
    format(toDate, 'yyyy-MM-dd'),
    getToken,
    pagination.pageIndex + 1,
    pagination.pageSize,
    sortingMap[sorting[0] ? sorting[0].id : 'default'],
    !sorting[0]?.desc,
    selectedTab in tabMap ? tabMap[selectedTab] : undefined
  )

  const { data: combinedAppointments, refetch: loadDashboardAppointments } =
    useQueryGetDashboardAppointments(
      clinicId,
      format(fromDate, 'yyyy-MM-dd'),
      format(toDate, 'yyyy-MM-dd'),
      getToken
    )

  const { refetch: loadVisitSummary } = useQueryGetVisitSummary(
    currentVisit?.id!,
    getToken
  )

  const tableInstanceRef = React.useRef<any>()

  const filteredFromDate = filterList.find(
    (dItem: any) => dItem.type === 'fromDate'
  )
  const filteredToDate = filterList.find(
    (dItem: any) => dItem.type === 'toDate'
  )

  React.useEffect(() => {
    if (clinics?.length === 0) {
      getClinics().catch((err: any) => {
        throw err
      })
    }

    loadDailyPatientSummary().catch((error) => {
      throw error
    })
  }, [])

  React.useEffect(() => {
    if (clinics?.length === 0) {
      getClinics().catch((err: any) => {
        throw err
      })
    }
  }, [lastDateUpdate])

  React.useEffect(() => {
    setCurrentDashboardTab(selectedTab)
  }, [selectedTab])

  React.useEffect(() => {
    setDashboardToDate(toDate)
  }, [toDate])

  React.useEffect(() => {
    setDashboardFromDate(fromDate)
  }, [fromDate])

  const handleShowFilterBox = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseFilterBox = (): void => {
    setAnchorEl(null)
    setFilterCategory('')
  }

  const handleRemoveFilter = (category: string): void => {
    const newFilterList = filterList.filter(
      (item: any) => item.category !== category
    )
    setFilterList(newFilterList)
  }

  const openFilterBox = Boolean(anchorEl)
  const openFilterByBox = Boolean(filterByAnchorEl)

  React.useEffect(() => {
    async function manageStorage(): Promise<void> {
      const url = process.env.S3_RELEASE_VERSION_URL
      const response = await fetch(url!)
      const currentReleaseVersion = await response.text()

      if (currentReleaseVersion !== releaseVersion) {
        // Define the keys you want to preserve
        const keysToPreserve = [
          'dashboardFromDate',
          'dashboardToDate',
          'clerk-db-jwt',
          'selectedProviders',
          'filterValue',
          'global-store',
          IS_METRIC_STORAGE_KEY
        ]

        // Temporarily store preserved data
        const preservedData: Record<string, string | null> = {}
        keysToPreserve.forEach((key) => {
          preservedData[key] = localStorage.getItem(key)
        })

        // Handle sent document IDs specifically
        const sentDocIds = Object.keys(localStorage).filter((key) =>
          key.includes('sentStatus-')
        )
        const sentDocs = sentDocIds.map((docId) => ({
          key: docId,
          value: localStorage.getItem(docId)!
        }))

        // Clear the entire localStorage
        localStorage.clear()

        // Restore preserved data
        Object.keys(preservedData).forEach((key) => {
          if (preservedData[key] !== null) {
            localStorage.setItem(key, preservedData[key]!)
          }
        })

        // Restore sent documents
        sentDocs.forEach((doc) => {
          localStorage.setItem(doc.key, doc.value)
        })

        // Clear specific IndexedDB databases
        await clearIndexedDB('patientProfiles-db')
        await clearIndexedDB('allergen-db')
        await clearIndexedDB('documents-db')
        await clearIndexedDB('dxcode-db')
        await clearIndexedDB('immunization-db')
        await clearIndexedDB('medication-db')

        // Finally, update the release version
        setReleaseVersion(currentReleaseVersion)
      }
    }

    // Only manage storage if the table data is not loading
    if (!isTableLoading) {
      manageStorage().catch((error) => {
        console.error('Error managing storage:', error)
      })
    }
  }, [isTableLoading]) // Added releaseVersion as a dependency

  React.useEffect(() => {
    loadClinicDashboardData().catch((error) => {
      console.error('Error loading data for tab:', selectedTab, error)
    })
  }, [
    filteredFromDate,
    filteredToDate,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    selectedTab
  ])

  const handlePaginationChange = (newPagination: { pageIndex: number, pageSize: number }) => {
    if (!newPagination || typeof newPagination.pageIndex !== 'number' || typeof newPagination.pageSize !== 'number') {
      console.error('Invalid pagination object', newPagination)
      return
    }
    if (newPagination.pageIndex === 0 && pagination.pageIndex > 1) return
  
    setPagination({ pageIndex: newPagination.pageIndex, pageSize: newPagination.pageSize })
    
    const currentParams = new URLSearchParams(window.location.search)
    
    currentParams.set('pageIndex', (newPagination.pageIndex).toString())
    currentParams.set('pageSize', newPagination.pageSize.toString())
  
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`
  
    router.push(newUrl)
  }
  
  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const pageIndexParam = searchParams.get('pageIndex')
    const pageSizeParam = searchParams.get('pageSize')

    const newPageIndex = pageIndexParam ? parseInt(pageIndexParam, 10) : 0
    const newPageSize = pageSizeParam ? parseInt(pageSizeParam, 10) : 10

    if (newPageIndex !== pagination.pageIndex || newPageSize !== pagination.pageSize) {
      setPagination({
        pageIndex: newPageIndex,
        pageSize: newPageSize,
      })
    }
  }, [router])

  React.useEffect(() => {
    const updatedTabsInfo: TabInfo[] = [
      {
        title: 'Appointments',
        count: clinicDashboardData?.visitCountersPerCategory?.appointments ?? 0
      },
      {
        title: 'Waiting Room',
        count: clinicDashboardData?.visitCountersPerCategory?.waitingRoom ?? 0
      },
      {
        title: 'Exam Started',
        count: clinicDashboardData?.visitCountersPerCategory?.examStarted ?? 0
      },
      {
        title: 'Completed',
        count: clinicDashboardData?.visitCountersPerCategory?.completed ?? 0
      }
    ]

    setTabsInfo(updatedTabsInfo)
  }, [clinicDashboardData])

  React.useEffect(() => {
    const fetchData = async () => {
      let data
      const dashboardData = Array.isArray(clinicDashboardData)
        ? clinicDashboardData[0]
        : clinicDashboardData

      switch (selectedTab) {
        case 'Appointments':
          data = await parseTableDataFromAppointment(
            combinedAppointments,
            getToken,
            true
          )
          break
        case 'Waiting Room':
        case 'Exam Started':
        case 'Completed':
        default:
          data = parseTableData(dashboardData)
          break
      }
      return data
    }

    const updateTabInfo = () => {
      const dashboardData = Array.isArray(clinicDashboardData)
        ? clinicDashboardData[0]
        : clinicDashboardData
      const counters = dashboardData?.visitCountersPerCategory || {}
      return tabsInfo.map((item) => {
        switch (item.title) {
          case 'Appointments':
            item.count = combinedAppointments?.length ?? 0
            break
          case 'Waiting Room':
            item.count = counters.waitingRoom ?? 0
            break
          case 'Exam Started':
            item.count = counters.examStarted ?? 0
            break
          case 'Completed':
            item.count = counters.completed ?? 0
            break
        }
        return item
      })
    }

    Promise.resolve(
      fetchData()
        .then((data) => {
          setTableData(data)
          setTabsInfo(updateTabInfo())
        })
        .catch((error) => console.error(error))
    )
  }, [selectedTab, combinedAppointments, clinicDashboardData])

  React.useEffect(() => {
    const scrollWidth =
      tableInstanceRef?.current.refs.tableContainerRef.current.scrollWidth
    if (scrollWidth > window.innerWidth) {
      setIsColumnFixed(true)
    } else {
      setIsColumnFixed(false)
    }
  }, [window.innerWidth])

  const handleToastClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return
    }
    setToastMessage('')
  }

  const handleCloseSendRecordModal = (): void => {
    setIsSendRecordsOpen(false)
  }

  const handleSendFax = (fax: DocumoFax): void => {
    loadVisitSummary()
      .then((res) => {
        if (res !== undefined) {
          const newFax = fax
          newFax.accountUserId = user?.publicMetadata.account_user_id as number
          newFax.visitId = currentVisit?.id ?? 0
          newFax.patientId = currentVisit?.patientId ?? 0
          newFax.clinicId = currentVisit?.clinicId ?? 0
          documoFaxMutation
            .mutateAsync({
              documentId: res.data as unknown as number,
              fax: newFax
            })
            .catch((err) => {
              throw err
            })
        }
      })
      .catch((err) => {
        throw err
      })
  }

  const handleOpenChart = React.useCallback((visit: Visit): void => {
    if (isEmpty(visit)) return
    window.location.href = `/charting/${visit?.id! ?? 0}?previousNavOpen=false`
    localStorage.setItem('past-chart', visit?.id?.toString()!)
  }, [])

  const handleChangeTab = (newValue: string): void => {
    setSelectedTab(newValue)
  }

  const handleSortingChange = (updatedSorting: MRT_SortingState): void => {
    setSorting(updatedSorting)
  }

  const onFilterApply = (category: string, value: any): void => {
    const existingFilterIndex = filterList.findIndex(
      (item: any) => item.category === category
    )

    if (existingFilterIndex !== -1) {
      filterList[existingFilterIndex].value = value
    } else {
      filterList.push({
        category,
        value
      })
    }
    setFilterList([...filterList])
  }

  const onFilterClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    category: string
  ): void => {
    event.stopPropagation()
    setFilterCategory(category)
    setFilterByAnchorEl(anchorEl)
    setAnchorEl(null)
  }

  const buildOrderNames = (procedure: Procedure): DashboardProcedure[] => {
    if (
      procedure?.visitClinicalProcedureList === null ||
      procedure?.visitClinicalProcedureList === undefined ||
      procedure?.visitClinicalProcedureList?.length === 0
    ) {
      return []
    }
    return procedure?.visitClinicalProcedureList
      .map((visitProcedure: any): any => {
        const type = procedureTypes?.find(
          (type) => type.id === (visitProcedure?.clinicalProcedureId ?? 0)
        )
        return {
          ...visitProcedure,
          name: type?.name,
          clinicalProcedure: type,
          chiefProcedures: [procedure]
        }
      })
      .filter((procedure) => !isEmpty(procedure.name))
  }

  const getPatientStatus = (item: any): string => {
    if (
      item?.visitProcedure?.visitProcedureCptCodeList?.some(
        (visitProcedure: ProcedureCPTCode) =>
          visitProcedure.clinicalProcedureStatusId === 1 ||
          visitProcedure.clinicalProcedureStatusId === 2
      )
    ) {
      return 'Pending Results'
    }
    if (
      !isEmpty(item?.visit?.arrivalDateTime) &&
      !isEmpty(item?.visit?.examStartDateTime) &&
      isEmpty(item?.visit?.lockingDateTime) &&
      isEmpty(item?.visit?.dischargeDateTime) &&
      isEmpty(item?.visit?.cancelDateTime)
    ) {
      return 'Opened'
    }
    if (
      !isEmpty(item?.visit?.arrivalDateTime) &&
      isEmpty(item?.visit?.dischargeDateTime) &&
      isEmpty(item?.visit?.examStartDateTime) &&
      isEmpty(item?.visit?.lockingDateTime) &&
      (isEmpty(item?.visit?.providerAccountUserId) ||
        isEmpty(item?.visit?.roomId))
    ) {
      return 'Waiting'
    }
    if (
      !isEmpty(item?.visit?.arrivalDateTime) &&
      isEmpty(item?.visit?.dischargeDateTime) &&
      isEmpty(item?.visit?.examStartDateTime) &&
      isEmpty(item?.visit?.lockingDateTime) &&
      !isEmpty(item?.visit?.providerAccountUserId) &&
      !isEmpty(item?.visit?.roomId)
    ) {
      return 'Booked'
    }
    if (
      !isEmpty(item?.visit?.arrivalDateTime) &&
      !isEmpty(item?.visit?.examStartDateTime) &&
      !isEmpty(item?.visit?.lockingDateTime) &&
      isEmpty(item?.visit?.cancelDateTime)
    ) {
      return 'Completed'
    }
    if (
      !isEmpty(item?.visit?.arrivalDateTime) &&
      !isEmpty(item?.visit?.dischargeDateTime) &&
      !isEmpty(item?.visit?.examStartDateTime) &&
      isEmpty(item?.visit?.lockingDateTime) &&
      isEmpty(item?.visit?.cancelDateTime)
    ) {
      return 'Discharged'
    }
    return 'Unknown' // or any default value
  }

  const parseTableData = (data: ClinicDashboard): any => {
    const dataArray = Array.isArray(data) ? data : [data]
    if (dataArray.length === 0) return []
    const parsedData = data?.patients?.map((item: any) => {
      return {
        patientDetails: {
          firstName: item.patient.firstName,
          lastName: item.patient.lastName,
          avatar: item.patient.avatarUrl,
          dateOfBirth: item.patient.dateOfBirth,
          age: formatAge(item.patient.dateOfBirth),
          gender: formatGender(item?.patient?.sex),
          phone: isEmpty(item.patient.phoneNumber)
            ? ''
            : formatPhoneNumber(item.patient.phoneNumber),
          id: item.patient.id
        },
        isVisitProcedurePendingResult: item.visit.isVisitProcedurePendingResult,
        providerId: isEmpty(item.visit.providerAccountUserId)
          ? 0
          : item.visit.providerAccountUserId,
        reasonVisitType: {
          visitType: visitTypes?.find(
            (visitTypeItem) =>
              visitTypeItem?.id === item?.visit?.chiefComplaint?.visitTypeId
          )?.name,
          reasonForVisit: complaints
            ?.filter((complaintItem) => {
              return (
                item?.visit?.chiefComplaint?.complaintList.findIndex(
                  (cItem: any) => cItem === complaintItem?.id
                ) !== -1
              )
            })
            .map((cItem) => cItem.name),
          notes: item?.visit?.chiefComplaint?.notes ?? ''
        },
        timeSinceArrival: differenceInMinutes(
          isEmpty(item?.visit?.dischargeDateTime)
            ? new Date(Date.now())
            : new Date(item?.visit?.dischargeDateTime),
          new Date(item?.visit?.arrivalDateTime)
        ),
        staff: item?.accountUsers.map(
          (uItem: any) =>
            `${uItem?.firstName as string} ${uItem?.lastName as string}`
        ),
        provider: getProviderFullName(
          item?.visit?.providerAccountUserId ?? 0,
          clinicians ?? []
        ),
        room: rooms?.find((roomItem) => roomItem?.id === item?.visit?.roomId)
          ?.name,
        vitals: item?.vital,
        orders: buildOrderNames(item?.visitProcedure),
        contacts: [
          item?.patient?.phoneNumber,
          !isEmpty(item?.patient?.emergencyContactPhone)
            ? item?.patient?.emergencyContactPhone
            : '',
          !isEmpty(item?.patient?.primaryCarePhysicianContact)
            ? item?.patient?.primaryCarePhysicianContact
            : ''
        ],
        dateOfService: isEmpty(item?.visit?.arrivalDateTime)
          ? ''
          : format(new Date(item?.visit?.arrivalDateTime), 'MM/dd/yyyy'),
        allDetails: item,
        statusString: getPatientStatus(item)
      }
    })
    return parsedData
  }

  const loadDailyPatientSummary = async (): Promise<void> => {
    if (clinicId === 0) return
    const dailyPatientSummaryPromise = getDailyPatientSummary(
      format(new Date(), 'yyyy-MM-dd'),
      clinicId,
      getToken
    )
    Promise.resolve(await dailyPatientSummaryPromise)
      .then((res) => {
        setDailyPatientSummary(res)
      })
      .catch((error) => {
        throw error
      })
  }

  const parseTime = (time: Date): Date => {
    const hour = time.getHours()
    let minute = time.getMinutes()
    if (minute < 15) {
      minute = 0
    } else if (minute < 30) {
      minute = 15
    } else if (minute < 45) {
      minute = 30
    } else {
      minute = 45
    }
    return new Date(
      time.getFullYear(),
      time.getMonth(),
      time.getDate(),
      hour,
      minute
    )
  }

  const handleAssignRoom = async (
    data: any,
    roomId: number,
    setHoverAssignRoomId: (value: string) => {}
  ): Promise<void> => {
    const { visit } = data
    visit.roomId = `${roomId}`
    visit.patient = undefined
    visit.chiefComplaint = undefined
    const scheduleStartTime = parseTime(addMinutes(new Date(), 15))
    visit.arrivalDateTime = formatInUTC(new Date())
    visit.scheduleStart = formatInUTC(scheduleStartTime)
    visit.scheduleEnd = formatInUTC(addMinutes(scheduleStartTime, 15))
    try {
      setHoverAssignRoomId('')
      await updateVisitMutation?.mutateAsync({
        visitId: visit?.id as number,
        visit
      })
    } catch (error) {
      if (!isEmpty(error)) {
        setToastMessage(JSON.parse(error as string).Message)
      }
    } finally {
      await loadClinicDashboardData()
    }
  }

  const handleAssignProvider = async (
    data: any,
    providerId: number,
    setToastMessage: (value: string) => void
  ): Promise<void> => {
    const { visit } = data
    visit.providerAccountUserId = providerId
    visit.patient = undefined
    visit.chiefComplaint = undefined
    const scheduleStartTime = parseTime(addMinutes(new Date(), 15))
    visit.arrivalDateTime = formatInUTC(new Date())
    visit.scheduleStart = formatInUTC(scheduleStartTime)
    visit.scheduleEnd = formatInUTC(addMinutes(scheduleStartTime, 15))
    try {
      await updateVisitMutation?.mutateAsync({
        visitId: visit?.id as number,
        visit
      })
    } catch (error) {
      if (!isEmpty(error)) {
        setToastMessage(JSON.parse(error as string).Message)
      }
    } finally {
      await loadClinicDashboardData()
    }
  }

  const handleDeleteVisit = (): void => {
    deleteVisitMutation
      .mutateAsync({ visitId: currentVisit?.id! })
      .catch((error) => {
        throw error
      })
      .finally(() => {
        loadClinicDashboardData().catch((err) => {
          throw err
        })
        setOpenDeleteDialog(false)
      })
  }

  const onDeleteAppointment = (): void => {
    if (appointmentId === undefined) {
      return
    }
    const appointment = combinedAppointments?.find(
      (app: TaggedAppointment) => app.id === appointmentId
    )
    if (appointment === undefined) {
      return
    }
    if (appointment.appointmentType === 'clinic') {
      const newOnlineAppointment: OnlineAppointment = {
        ...appointment,
        status: 4,
        statusString: 'NoShow'
      }
      updateOnlineAppointment
        .mutateAsync({
          onlineAppointmentId: appointmentId,
          onlineAppointment: newOnlineAppointment
        })
        .then(async () => {
          await loadDashboardAppointments()
        })
        .catch((err) => {
          throw err
        })
    } else if (appointment.appointmentType === 'public') {
      deletePublicAppointment
        .mutateAsync(appointmentId)
        .then(async () => {
          await loadDashboardAppointments()
        })
        .catch((err) => {
          throw err
        })
    }
    setOpenDeleteDialog(false)
  }

  const goToIntake = (data: any) => (): void => {
    const queryParams = [
      previousNavOpen && 'previousNavOpen=true',
      data?.patientDetails?.firstName &&
        `firstName=${data?.patientDetails?.firstName as string}`,
      data?.patientDetails?.lastName &&
        `lastName=${data?.patientDetails?.lastName as string}`,
      data?.phone && `phone=${data?.phone as string}`,
      data?.email && `email=${data?.email as string}`,
      data?.patientDetails?.dateOfBirth &&
        `dateOfBirth=${JSON.stringify(data?.patientDetails?.dateOfBirth)}`,
      data?.id && `appointmentId=${data?.id as number}`,
      data?.providerId && `providerId=${data?.providerId as number}`,
      data?.roomId && `roomId=${data?.roomId as string}`,
      data?.visitTypeId && `visitTypeId=${data?.visitTypeId as string}`,
      data?.appointmentEndDate &&
        `appointmentEndDate=${new Date(
          data?.appointmentEndDate
        ).toISOString()}`,
      data?.appointmentStartDate &&
        `appointmentStartDate=${new Date(
          data?.appointmentStartDate
        ).toISOString()}`,
      data?.visitTypeCategoryId &&
        `visitTypeCategoryId=${data?.visitTypeCategoryId as string}`
    ]
      .filter(Boolean)
      .join('&')

    const urlString = `/intake?${queryParams}`
    router.push(urlString)
  }

  const theme = useTableTheme()

  const handleDoubleClickTable = React.useCallback(
    (row: any) => {
      if (selectedTab === tabsInfo[0].title) {
        goToIntake(row?.original)()
      } else {
        handleOpenChart(row?.original?.allDetails?.visit as Visit)
      }
    },
    [selectedTab, goToIntake, handleOpenChart]
  )

  const originalColumns = useGetOriginalColumns(
    setCurrentVisit,
    setIsSendRecordsOpen,
    setCreateTaskOpen,
    (task: any) => setCurrentPatientId(task?.patientId ?? null),
    setStickyNotesOpen,
    setCurrentPatientId,
    setOpenDeleteDialog,
    handleAssignRoom,
    handleAssignProvider,
    setHoverAssignRoomId,
    setHoverAssignProviderId,
    loadClinicDashboardData,
    loadDashboardAppointments,
    rooms ?? [],
    clinicians ?? [],
    clinicId,
    handleDoubleClickTable,
    hoverAssignProviderId,
    hoverAssignRoomId,
    updateVisitStatus,
    theme
  )

  const appointmentColumns = useGetAppointmentColumns(
    goToIntake,
    setAppointmentId,
    setOpenDeleteDialog,
    updateAppointmentStatus,
    () => {
      loadClinicDashboardData().catch((err) => {
        throw err
      })
      loadDashboardAppointments().catch((err) => {
        throw err
      })
    },
    clinicians ?? [],
    theme
  )

  const columns = React.useMemo(() => {
    let filterColumns = []
    switch (selectedTab) {
      case tabsInfo[1].title:
        filterColumns = originalColumns.filter(
          (col) =>
            !['date_of_service', 'order', 'procedures'].includes(col.id ?? '')
        )
        break
      case tabsInfo[3].title:
        filterColumns = originalColumns.filter(
          (col) => !['staff', 'room', 'vitals'].includes(col.id ?? '')
        )
        break
      default:
        filterColumns = originalColumns.filter(
          (col) => col.id !== 'date_of_service'
        )
    }
    return selectedTab === tabsInfo[0].title
      ? appointmentColumns
      : filterColumns
  }, [selectedTab, originalColumns, appointmentColumns])

  const handleAddFilter = (from: Date, to: Date): void => {
    setFromDate(from)
    setToDate(to)
  }

  const pinnedColumn =
    selectedTab !== tabsInfo[0].title
      ? ['clinic-actions', 'patient_details']
      : ['appointment-actions', 'patient_details']

  const memoizedUnifiedTable = React.useMemo(
    () => (
      <UnifiedTable
        columns={columns}
        filteredTableData={tableData}
        tableInstanceRef={tableInstanceRef}
        isTableLoading={isTableLoading}
        isColumnFixed={isColumnFixed}
        pinnedColumn={pinnedColumn}
        selectedTab={selectedTab}
        tabList={tabsInfo}
        pagination={{
          pageIndex: parseInt(paginationIndex),
          pageSize: pagination.pageSize,
        }}
        setPagination={(updaterOrNewState: any) => {
          const newPagination = typeof updaterOrNewState === 'function'
            ? updaterOrNewState(pagination)
            : updaterOrNewState      
          handlePaginationChange(newPagination)
        }}
        sorting={sorting}
        setSorting={handleSortingChange}
        handleDoubleClickTable={handleDoubleClickTable}
        handleChangeTab={handleChangeTab}
        tabsInfo={tabsInfo}
        filterList={filterList}
        handleRemoveFilter={handleRemoveFilter}
        enableColumnResizing={false}
        totalCount={
          selectedTab === 'Appointments'
            ? tableData.length
            : clinicDashboardData?.totalCount ?? 0
        }
      />
    ),
    [
      columns,
      tableData,
      tableInstanceRef,
      isTableLoading,
      isColumnFixed,
      pinnedColumn,
      selectedTab,
      tabsInfo,
      pagination,
      setPagination,
      sorting,
      handleSortingChange,
      handleDoubleClickTable,
      handleChangeTab,
      tabsInfo,
      filterList,
      handleRemoveFilter,
      clinicDashboardData?.totalCount
    ]
  )

  return (
    <DashboardContainer>
      <TitleBar
        pageTitle="Dashboard"
        isNavOpen={isNavOpen}
        setIsNavOpen={setIsNavOpen}
      />
      <DashboardContent>
        <NavColumn
          selectedIndex={1}
          isNavOpen={isNavOpen}
        />
        <DashboardContentPane isNavOpen={isNavOpen}>
          <DashboardTopPane
            waitingsCount={
              clinicDashboardData?.visitCountersPerCategory?.waitingRoom ?? 0
            }
            openChartCount={
              clinicDashboardData?.visitCountersPerCategory?.examStarted ?? 0
            }
            setSelectedTab={setSelectedTab}
            averageCurrentPatientTimeInClinic={
              dailyPatientSummary?.averageCurrentPatientTimeInClinic ?? 0
            }
          />
          <DashboardMainPane>
            <Header>
              <Button
                sx={{ fontWeight: 600, width: 'fit-content' }}
                variant="contained"
                onClick={() => {
                  window.location.href = `/intake?previousNavOpen=${String(
                    isNavOpen
                  )}`
                }}
                size="medium"
              >
                Add Visit
              </Button>
              <Stack
                direction="row"
                gap={1}
                alignItems={'center'}
              >
                <FilterButton
                  variant="text"
                  onClick={handleShowFilterBox}
                >
                  <AddIcon />
                  Add Filter
                </FilterButton>
                <TimeRangeFilterPopup
                  fromDate={fromDate}
                  toDate={toDate}
                  onApply={handleAddFilter}
                />
              </Stack>
              <VisitFilterPopup
                isOpen={openFilterBox}
                anchorEl={anchorEl}
                onClose={handleCloseFilterBox}
                onFilterClick={onFilterClick}
              />
              <FilterByPopup
                isOpen={openFilterByBox}
                anchorEl={filterByAnchorEl}
                onClose={() => setFilterByAnchorEl(null)}
                category={filterCategory}
                onDone={onFilterApply}
              />
            </Header>
            <Box>
              <ThemeProvider theme={theme}>
                {memoizedUnifiedTable}
              </ThemeProvider>
            </Box>
          </DashboardMainPane>
        </DashboardContentPane>
      </DashboardContent>
      <Dialog
        open={openDeleteDialog}
        sx={{ borderRadius: '12px' }}
      >
        <DeleteVisitPopUp
          handleDelete={
            selectedTab === tabsInfo[0].title
              ? onDeleteAppointment
              : handleDeleteVisit
          }
          setClose={() => setOpenDeleteDialog(false)}
        />
      </Dialog>
      <Modal
        open={isSendRecordsOpen}
        onClose={handleCloseSendRecordModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <SendRecord
          handleSend={handleSendFax}
          handleClose={handleCloseSendRecordModal}
        />
      </Modal>
      <Dialog
        open={createTaskOpen}
        sx={{ borderRadius: '12px' }}
        maxWidth={false}
        onClose={() => setCreateTaskOpen(false)}
      >
        <CreateTaskModal
          defaultTask={{ patientId: currentPatientId }}
          setClose={() => setCreateTaskOpen(false)}
        />
      </Dialog>
      <Modal
        open={stickyNotesOpen}
        onClose={() => setStickyNotesOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <StickyNotesModal
          patientId={currentPatientId}
          handleClose={() => setStickyNotesOpen(false)}
          getPatient={loadClinicDashboardData}
        />
      </Modal>
      <Snackbar
        open={!isEmpty(toastMessage)}
        autoHideDuration={6000}
        onClose={handleToastClose}
      >
        <Alert
          onClose={handleToastClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </DashboardContainer>
  )
}

interface TabInfo {
  title: string
  count: number
}

export default Dashboard
